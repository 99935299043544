<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">

      <!-- <a-form-model-item label="处理状态" prop="processStatus">
        <a-radio-group v-model="form.processStatus" button-style="solid">
          <a-radio-button :value="2">处理</a-radio-button>
          <a-radio-button :value="3">暂不处理</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item label="处理结果" prop="processResult" >
        <a-textarea v-model="form.processResult" placeholder="请输入处理结果" />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getFeedback, addFeedback, updateFeedback } from '@/api/valve/feedback'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        content: null,

        processStatus: 2,

        processResult: null,

        imgUrls: null,

        contact: null,

        remark: null,

        createTime: null

      },
      // 1增加,2修改
      formType: 2,
      open: false,
      rules: {
        processStatus: [
          { required: true, message: '请选择处理状态', trigger: 'blur' }
        ],
        processResult: [
          { required: true, message: '请输入处理结果', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        content: null,
        processStatus: 2,
        processResult: null,
        imgUrls: null,
        contact: null,
        remark: null,
        createTime: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getFeedback({ 'id': id }).then(response => {
        this.form = response.data
        this.form.processStatus = 2
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateFeedback(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addFeedback(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
